import axios from '@axios'

const getDoctors = async params => {
  const { status, data } = await axios.get('/admin/doctors', { params })

  if (status === 200) {
    return data
  }

  return null
}

const importDoctorList = async file => {
  const { status, data } = await axios.post('/admin/doctors/import', file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  if (status === 200) {
    return data
  }

  return null
}

export { getDoctors, importDoctorList }
