var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "site-items-list"
    }
  }, [_c('v-breadcrumbs', {
    attrs: {
      "divider": "/",
      "large": "",
      "items": _vm.breadcrumbs
    }
  }), _c('v-card', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "filter-section"
  }, [_c('v-row', {}, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card-title', {
    staticClass: "font-weight-bold "
  }, [_vm._v(" " + _vm._s(_vm.$t('common.list.filters')) + " ")])], 1), _c('v-col', {
    staticClass: "d-flex justify-end",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.showFilter = !_vm.showFilter;
      }
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.showFilter ? _vm.icons.mdiMenuUp : _vm.icons.mdiMenuDown))])], 1)], 1)], 1), _c('v-expand-transition', [_c('v-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showFilter,
      expression: "showFilter"
    }],
    staticClass: "px-2 ma-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "placeholder": _vm.$t('common.list.selectSite'),
      "items": _vm.sitesList,
      "item-text": "url",
      "item-value": "id",
      "single-line": "",
      "dense": "",
      "outlined": "",
      "clearable": "",
      "hide-details": ""
    },
    model: {
      value: _vm.filters.site_id,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "site_id", $$v);
      },
      expression: "filters.site_id"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "placeholder": _vm.$t('common.list.selectPage'),
      "items": _vm.filteredSitePages,
      "item-text": "href",
      "item-value": "id",
      "single-line": "",
      "dense": "",
      "outlined": "",
      "clearable": "",
      "hide-details": ""
    },
    model: {
      value: _vm.filters.page_id,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "page_id", $$v);
      },
      expression: "filters.page_id"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "placeholder": _vm.$t('common.list.selectSection'),
      "items": _vm.filteredSiteSections,
      "item-text": "key",
      "item-value": "id",
      "single-line": "",
      "dense": "",
      "outlined": "",
      "clearable": "",
      "hide-details": ""
    },
    model: {
      value: _vm.filters.section_id,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "section_id", $$v);
      },
      expression: "filters.section_id"
    }
  })], 1)], 1)], 1)], 1), _c('v-row', {
    staticClass: "my-0 d-flex justify-end"
  }, [_c('v-col', {
    staticStyle: {
      "margin-right": "auto"
    },
    attrs: {
      "cols": "12",
      "sm": "2",
      "align": "right"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "block": "",
      "loading": _vm.loading,
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.loadData
    }
  }, [_c('v-icon', {
    staticClass: "prefix-icon"
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiRefresh) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('common.list.refresh')) + " ")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "2",
      "align": "right"
    }
  }, [_c('v-btn', {
    attrs: {
      "depressed": "",
      "block": "",
      "color": "success"
    },
    on: {
      "click": function click($event) {
        return _vm.setAside(null);
      }
    }
  }, [_c('v-icon', {
    staticClass: "prefix-icon"
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]), _vm._v(" " + _vm._s(_vm.$t('common.list.create')) + " ")], 1)], 1)], 1), _c('v-card', [_c('v-data-table', {
    staticClass: "text-no-wrap site-item-table",
    attrs: {
      "headers": _vm.tableColumns,
      "items": _vm.tableItems,
      "options": _vm.options,
      "server-items-length": _vm.tableTotal,
      "loading": _vm.loading,
      "header-props": _vm.headerprops
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.element.text",
      fn: function fn(_ref) {
        var _item$element;
        var item = _ref.item;
        return [(_item$element = item.element) !== null && _item$element !== void 0 && _item$element.text ? _c('span', {
          staticClass: "text-wrap text-limited",
          on: {
            "click": function click($event) {
              item.isExpanded = true;
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.t(item.element.text)) + " ")]) : _vm._e()];
      }
    }, {
      key: "item.asset_id",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.asset ? _c('span', [_vm._v(" " + _vm._s(item.asset.key) + " "), _c('br'), _vm._v(" (" + _vm._s(item.asset.notes) + ") ")]) : _vm._e()];
      }
    }, {
      key: "item.src_href",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm.getImageSrc(item) ? _c('img', {
          style: {
            maxWidth: '100%',
            maxHeight: '100px'
          },
          attrs: {
            "src": _vm.getImageSrc(item)
          }
        }) : _c('span', {
          staticClass: "text-wrap text-limited",
          on: {
            "click": function click($event) {
              item.isExpanded = true;
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.t(item.element.src)) + " ")]), item.asset_id ? _c('p', [_vm._v(" (Asset: #" + _vm._s(item.asset_id) + ") ")]) : _vm._e(), item.element.href ? _c('span', [_vm._v(" " + _vm._s(item.element.href) + " ")]) : _vm._e()];
      }
    }, {
      key: "item.element",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', {
          staticClass: "text-wrap text-limited"
        }, [_vm._v(" " + _vm._s(item.element) + " ")])];
      }
    }, {
      key: "item.value",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', {
          staticClass: "text-wrap"
        }, [_vm._v(_vm._s(_vm.t(item.value)))])];
      }
    }, {
      key: "item.feature_id",
      fn: function fn(_ref6) {
        var _item$feature;
        var item = _ref6.item;
        return [_c('span', {
          staticClass: "text-wrap"
        }, [_vm._v(_vm._s(((_item$feature = item.feature) === null || _item$feature === void 0 ? void 0 : _item$feature.name.en) || '--'))])];
      }
    }, {
      key: "item.notes",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('span', {
          staticClass: "text-wrap"
        }, [_vm._v(_vm._s(item.notes))])];
      }
    }, {
      key: "item.active",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(item.active ? 'Yes' : 'No'))])];
      }
    }, {
      key: "item.reserved",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(item.reserved ? 'Yes' : 'No'))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('div', {
          staticClass: "d-flex align-center justify-center"
        }, [_c('v-menu', {
          attrs: {
            "bottom": "",
            "left": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref11) {
              var on = _ref11.on,
                attrs = _ref11.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "icon": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "size": "18"
                }
              }, [_vm._v(" " + _vm._s(_vm.icons.mdiDotsVertical) + " ")])], 1)];
            }
          }], null, true)
        }, [_c('v-list', [_c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              return _vm.setAside(item);
            }
          }
        }, [_c('v-list-item-title', [_c('v-icon', {
          staticClass: "me-2",
          attrs: {
            "size": "18"
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPencilOutline) + " ")]), _c('span', [_vm._v("Edit")])], 1)], 1), _c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              return _vm.deleteSiteItems(item);
            }
          }
        }, [_c('v-list-item-title', [_c('v-icon', {
          staticClass: "me-2",
          attrs: {
            "size": "18"
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiDeleteOutline) + " ")]), _c('span', [_vm._v("Delete")])], 1)], 1)], 1)], 1)], 1)];
      }
    }], null, true)
  })], 1), _vm.aside ? _c('site-items-aside', {
    attrs: {
      "resource": _vm.resource
    },
    on: {
      "changed": function changed($event) {
        _vm.loadData();
        _vm.aside = false;
      }
    },
    model: {
      value: _vm.aside,
      callback: function callback($$v) {
        _vm.aside = $$v;
      },
      expression: "aside"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }