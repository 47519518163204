<script>
import { importDoctorList } from '@/api/doctor'
import { mdiFileImport } from '@mdi/js'
import { computed, onMounted, ref } from '@vue/composition-api'
import store from '@/store'

export default {
  setup(props, { emit }) {
    const isDialogVisible = ref(false)
    const isSnackbarVisible = ref(false)
    const loading = ref(false)

    const groupId = ref(null)
    const file = ref(null)

    const groups = computed(() => {
      return store.getters['doctor_list/getGroups'].map(item => ({
        value: item.id,
        title: item.name,
      }))
    })

    const resetForm = () => {
      file.value = null
    }

    const closeDialog = () => {
      resetForm()

      isDialogVisible.value = false
    }

    const handleSubmit = async () => {
      loading.value = true

      try {
        const form = new FormData()
        form.append('group_id', groupId.value)
        form.append('file', file.value)

        const { status } = await importDoctorList(form)

        if (status === 'success') {
          resetForm()

          isDialogVisible.value = false
          isSnackbarVisible.value = true
        }
      } catch (error) {
        console.log(data)
      } finally {
        loading.value = false
      }
    }

    onMounted(() => {})

    return {
      isDialogVisible,
      isSnackbarVisible,
      loading,

      groupId,
      file,
      groups,

      icons: {
        mdiFileImport,
      },

      closeDialog,
      handleSubmit,
    }
  },
}
</script>

<template>
  <div>
    <v-snackbar color="success" v-model="isSnackbarVisible" location="top" :timeout="5000" transition="scroll-y-reverse-transition">
      Doctor list has been uploaded. You will notified via Slack once the job is done.
      <template #actions>
        <v-btn color="success" @click="isSnackbarVisible = false"> OK </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="isDialogVisible" persistent max-width="600px" height="400">
      <!-- Dialog Activator -->
      <template #activator="{ on, attrs }">
        <v-btn color="primary" outlined class="mb-4" v-bind="attrs" v-on="on">
          <v-icon size="17" class="me-1">
            {{ icons.mdiFileImport }}
          </v-icon>
          <span>Import</span>
        </v-btn>
      </template>

      <!-- Dialog Content -->
      <v-card title="Import Doctor List">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="groupId"
                :items="groups"
                item-text="title"
                item-value="value"
                hide-details
                label="Select a group"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-file-input v-model="file" chips accept=".csv" label="Upload .csv file" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="error" outlined @click="closeDialog" :disabled="loading"> Close </v-btn>
          <v-btn color="success" @click="handleSubmit" :disabled="loading || file === null" :loading="loading"> Import </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
