var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-snackbar', {
    attrs: {
      "color": "success",
      "location": "top",
      "timeout": 5000,
      "transition": "scroll-y-reverse-transition"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "color": "success"
          },
          on: {
            "click": function click($event) {
              _vm.isSnackbarVisible = false;
            }
          }
        }, [_vm._v(" OK ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.isSnackbarVisible,
      callback: function callback($$v) {
        _vm.isSnackbarVisible = $$v;
      },
      expression: "isSnackbarVisible"
    }
  }, [_vm._v(" Doctor list has been uploaded. You will notified via Slack once the job is done. ")]), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "600px",
      "height": "400"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "mb-4",
          attrs: {
            "color": "primary",
            "outlined": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          staticClass: "me-1",
          attrs: {
            "size": "17"
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiFileImport) + " ")]), _c('span', [_vm._v("Import")])], 1)];
      }
    }]),
    model: {
      value: _vm.isDialogVisible,
      callback: function callback($$v) {
        _vm.isDialogVisible = $$v;
      },
      expression: "isDialogVisible"
    }
  }, [_c('v-card', {
    attrs: {
      "title": "Import Doctor List"
    }
  }, [_c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.groups,
      "item-text": "title",
      "item-value": "value",
      "hide-details": "",
      "label": "Select a group"
    },
    model: {
      value: _vm.groupId,
      callback: function callback($$v) {
        _vm.groupId = $$v;
      },
      expression: "groupId"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-file-input', {
    attrs: {
      "chips": "",
      "accept": ".csv",
      "label": "Upload .csv file"
    },
    model: {
      value: _vm.file,
      callback: function callback($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "error",
      "outlined": "",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v(" Close ")]), _c('v-btn', {
    attrs: {
      "color": "success",
      "disabled": _vm.loading || _vm.file === null,
      "loading": _vm.loading
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v(" Import ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }