<template>
  <div id="site-items-list">
    <v-breadcrumbs
      divider="/"
      large
      :items="breadcrumbs"
    ></v-breadcrumbs>

    <v-card
      v-show="false"
      class="filter-section"
    >
      <v-row class="">
        <v-col cols="6">
          <v-card-title class="font-weight-bold ">
            {{ $t('common.list.filters') }}
          </v-card-title>
        </v-col>
        <v-col
          cols="6"
          class="d-flex justify-end"
        >
          <v-btn
            icon
            @click="showFilter = !showFilter"
          >
            <v-icon>{{ showFilter ? icons.mdiMenuUp : icons.mdiMenuDown }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-expand-transition>
        <v-row
          v-show="showFilter"
          class="px-2 ma-0"
        >
          <!-- role filter -->

          <v-col
            cols="12"
            sm="3"
          >
            <v-select
              v-model="filters.site_id"
              :placeholder="$t('common.list.selectSite')"
              :items="sitesList"
              item-text="url"
              item-value="id"
              single-line
              dense
              outlined
              clearable
              hide-details
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <v-select
              v-model="filters.page_id"
              :placeholder="$t('common.list.selectPage')"
              :items="filteredSitePages"
              item-text="href"
              item-value="id"
              single-line
              dense
              outlined
              clearable
              hide-details
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <v-select
              v-model="filters.section_id"
              :placeholder="$t('common.list.selectSection')"
              :items="filteredSiteSections"
              item-text="key"
              item-value="id"
              single-line
              dense
              outlined
              clearable
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-card>
    <v-row class="my-0 d-flex justify-end">
      <v-col
        cols="12"
        sm="2"
        style="margin-right:auto"
        align="right"
      >
        <v-btn
          color="primary"
          block
          class=""
          :loading="loading"
          :disabled="loading"
          @click="loadData"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiRefresh }}
          </v-icon>
          <span>
            {{ $t('common.list.refresh') }}
          </span>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="2"
        align="right"
      >
        <v-btn
          depressed
          block
          color="success"
          @click="setAside(null)"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiPlus }}
          </v-icon>
          {{ $t('common.list.create') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-card>
      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="tableItems"
        :options.sync="options"
        :server-items-length="tableTotal"
        :loading="loading"
        class="text-no-wrap site-item-table"
        :header-props="headerprops"
      >
        <!-- element.text -->
        <template #[`item.element.text`]="{ item }">
          <span
            v-if="item.element?.text"
            class="text-wrap text-limited"
            @click="item.isExpanded = true;"
          >
            {{ t(item.element.text) }}
          </span>
        </template>

        <!-- src or href -->
        <template #[`item.asset_id`]="{ item }">
          <span v-if="item.asset">
            {{ item.asset.key }}
            <br>
            ({{ item.asset.notes }})
          </span>
        </template>

        <!-- src or href -->
        <template #[`item.src_href`]="{ item }">
          <!-- <template
            v-if="item.asset_id"
          >
            <img
              :src="getThumbnail(item.asset_id)"
              :style="{ maxWidth: '100px', maxHeight: '100px'}"
            >
          </template> -->

          <!-- <img
            v-else-if="isExternalImageItem(item)"
            :src="t(item.element.src)"
            :style="{ maxWidth: '100%'}"
          > -->

          <img
            v-if="getImageSrc(item)"
            :src="getImageSrc(item)"
            :style="{ maxWidth: '100%', maxHeight: '100px'}"
          >

          <span
            v-else
            class="text-wrap text-limited"
            @click="item.isExpanded = true;"
          >
            {{ t(item.element.src) }}
          </span>

          <p v-if="item.asset_id">
            (Asset: #{{ item.asset_id }})
          </p>

          <span v-if="item.element.href">
            {{ item.element.href }}
          </span>
        </template>

        <!-- element -->
        <template #[`item.element`]="{ item }">
          <span class="text-wrap text-limited">
            {{ (item.element) }}
          </span>
        </template>

        <!-- value -->
        <template #[`item.value`]="{ item }">
          <span class="text-wrap">{{ t(item.value) }}</span>
        </template>

        <!-- notes -->
        <template #[`item.feature_id`]="{ item }">
          <span class="text-wrap">{{ item.feature?.name.en || '--' }}</span>
        </template>

        <!-- notes -->
        <template #[`item.notes`]="{ item }">
          <span class="text-wrap">{{ item.notes }}</span>
        </template>

        <!-- active-->
        <template #[`item.active`]="{ item }">
          <span class="text-no-wrap">{{ item.active ? 'Yes' : 'No' }}</span>
        </template>

        <!-- reserved-->
        <template #[`item.reserved`]="{ item }">
          <span class="text-no-wrap">{{ item.reserved ? 'Yes' : 'No' }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <div class="d-flex align-center justify-center">
            <!-- dropdown -->
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  link
                  @click="setAside(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <span>Edit</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  link
                  @click="deleteSiteItems(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    <span>Delete</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <site-items-aside
      v-if="aside"
      v-model="aside"
      :resource="resource"
      @changed="loadData(); aside = false"
    />

    <!-- <form-aside
      v-if="aside"
      v-model="aside"
      :resource="resource"
      @changed="
        loadCards()
        aside = false
      "
    ></form-aside> -->
  </div>
</template>

<script>
import {
mdiDeleteOutline, mdiDotsVertical,
mdiMenuDown,
mdiMenuUp,
mdiPencilOutline,
mdiPlus,
mdiRefresh,
} from '@mdi/js'

import ImportDialog from '@/modules/doctor/components/ImportDialog'
import useSites from '@/modules/site/views/useSites'
import { t } from '@/plugins/i18n'
import router from '@/router'
import { formatDate } from '@core/utils/filter'
import {
onMounted, reactive,
ref, watch,
} from '@vue/composition-api'
import _ from 'lodash'
import SiteItemsAside from './site-items-resource/SiteItemsAside.vue'
import useSiteItem from './useSiteItem'
import useSiteItems from './useSiteItems'

export default {
  components: {
    ImportDialog,
    SiteItemsAside,
  },
  setup() {
    const {
      tableItems, tableColumns, tableTotal, options, filters, loading, loadData, deleteSiteItems,
    } = useSiteItems()
    const {
      isExternalImageItem, getImageSrc,
    } = useSiteItem()
    const {
      sitesList, loadSites, sitePagesList, loadSitePages, siteSectionsList, loadSiteSections, siteAssetsList, loadSiteAssets,
    } = useSites()

    const actions = ['Edit']

    const aside = ref(false)
    const setAside = item => {
      aside.value = true
      resource.value = item
    }

    const resource = ref(null)

    // const categories = ref([])

    // const loadCategories = async () => {
    //   try {
    //     const {
    //       status,
    //       data: {
    //         data: { records },
    //       },
    //     } = await fetchDistributors()

    //     if (status === 200) {
    //       categories.value = records.map(record => {
    //         return { title: record.name.en, value: record.id }
    //       })
    //     }
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }

    // loadCategories()
    const breadcrumbs = ref([
      {
        text: 'Site Info',
        disabled: false,
        href: '/sites',
      },
      {
        text: 'Site Pages',
        disabled: false,
        href: `/sites/${router.currentRoute.params.site_id}/site-pages`,
      },
      {
        text: 'Site Sections',
        disabled: false,
        href: `/sites/${router.currentRoute.params.site_id}/site-pages/${router.currentRoute.params.site_page_id}/site-sections`,
      },
      {
        text: 'Site Items',
        disabled: true,
        href: null,
      },
    ])

    const singleSelect = ref(false)
    const headerprops = ref({
      'sort-icon': mdiMenuDown,
    })

    const showFilter = ref(true)
    const filteredSitePages = ref(null)
    const filteredSiteSections = ref(null)

    const updateFilteredSitePages = () => {
      if (filters && filters.site_id) {
        filteredSitePages.value = sitePagesList.value.filter(page => page.site_id === filters.site_id)
      } else {
        console.log('sitePagesList.value', sitePagesList.value)
        console.log('filteredSitePages.value', filteredSitePages.value)
        filteredSitePages.value = sitePagesList.value || []
      }
    }

    const updateFilteredSiteSections = () => {
      if (filters && filters.page_id) {
        filteredSiteSections.value = siteSectionsList.value.filter(section => section.page_id === filters.page_id)
      } else {
        console.log('siteSectionsList.value', siteSectionsList.value)
        filteredSiteSections.value = siteSectionsList.value || []
      }
    }

    const logClick = message => {
      console.log(message)
    }

    watch(
      () => filters.site_id,
      () => {
        console.log('filters.site_id', filters.site_id)
        updateFilteredSitePages()
      },
    )

    watch(
      () => filters.page_id,
      () => {
        // filters.section_id = null
        console.log('filters.page_id', filters.page_id)
        updateFilteredSiteSections()
      },
    )

    onMounted(async () => {
      await loadSites()
      await loadSitePages()
      await loadSiteSections()
      await loadSiteAssets()
      filters.site_id = parseInt(router.currentRoute.params.site_id)
      filters.page_id = parseInt(router.currentRoute.params.site_page_id)
      filters.section_id = parseInt(router.currentRoute.params.site_section_id)
      updateFilteredSitePages()
      updateFilteredSiteSections()
    })

    const wrappedTableItems = ref([])
    watch(
      tableItems,
      newTableItems => {
        wrappedTableItems.value = newTableItems.map(item => reactive({
          ...item,
          isExpanded: false,
        }))
      },
      {
        immediate: true,
      },
    )

    return {
      loading,
      t,
      formatDate,
      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuUp,
        mdiRefresh,
        mdiPlus,
      },

      // categories,
      setAside,
      aside,
      resource,

      tableColumns,
      tableItems: wrappedTableItems,
      tableTotal,
      options,
      filters,

      sitesList,
      sitePagesList,
      siteSectionsList,
      siteAssetsList,
      filteredSitePages,
      filteredSiteSections,

      actions,

      loadData,
      deleteSiteItems,
      isExternalImageItem,
      getImageSrc,
      singleSelect,
      headerprops,
      showFilter,
      logClick,
      breadcrumbs,
      _,
    }
  },
}
</script>

<style lang="scss">
.text-limited {
  max-height: 100px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
